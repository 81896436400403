import React, { useState, useEffect, ReactEventHandler } from "react";

function TaskItem(props: any) {
    const { task, index, provided, deleteCallback, switchCallback, changeTimeCallback, renameTaskCallback, day_active } = props;

    let [done, setDone] = useState(task.done);
    let [text, setText] = useState(task.text);
    let [show_form, setShowForm] = useState(false);
    let [show_time_form, toggleShowTimeForm] = useState(false);
    let [task_time, setTaskTime] = useState(task.time ? task.time : "10");

    const switchDone = () => {
        console.log("switchDone");
        setDone(!done); switchCallback();
    }

    const deleteTask = () => {
        deleteCallback();
    }

    const changeTime = () => {
        toggleShowTimeForm(!show_time_form);
        changeTimeCallback(task_time, index);
    }

    const renameTask = () => {
        setShowForm(false);
        renameTaskCallback(text, index);
    }

    return (
        <div className="task" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <div className={show_form ? 'task__form-rename' : 'form-check task__form-check'}>
                {show_form  ? (
                    <form className="oneline__form"  onSubmit={() => renameTask()}>
                        <input placeholder="Task name" type="text" defaultValue={text} onChange={(e) => setText(e.target.value)} className="oneline__input" />
                        <input type="submit" className="btn btn_green oneline__button" value="Save" disabled={text.length < 3} />
                        <button className="btn btn_transparent oneline__close">
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.963 9.57107L7.39189 5.99998L10.963 2.42888C11.3485 2.04333 11.3485 1.42263 10.963 1.03708C10.5774 0.651538 9.95674 0.651538 9.57119 1.03708L6.0001 4.60818L2.429 1.03708C2.04346 0.651538 1.42275 0.651538 1.03721 1.03708C0.65166 1.42263 0.65166 2.04333 1.03721 2.42888L4.6083 5.99998L1.03721 9.57107C0.65166 9.95662 0.65166 10.5773 1.03721 10.9629C1.42275 11.3484 2.04346 11.3484 2.429 10.9629L6.0001 7.39177L9.57119 10.9629C9.95674 11.3484 10.5774 11.3484 10.963 10.9629C11.3458 10.5773 11.3458 9.95388 10.963 9.57107Z" fill="#969EA3" fillOpacity="0.51" />
                            </svg>
                        </button>
                    </form>
                ) : (
                    <div className="form-group"> <input onChange={switchDone} defaultChecked={task.done} className="form-check-input task__checkbox" type="checkbox" value="" id={'TaskCheckbox' + task.id} />
                        <label className="form-check-label task__title" htmlFor={'TaskCheckbox' + task.id}>
                            {text ? text : task.routine.name}
                        </label>
                    </div>
                )
                }
                {!show_form && (
                    <div className={done ? "task__time visible" : "task__time"}>
                        Spent
                        {show_time_form ?
                            <div className="task__time-form">
                                <input type="number" className="task__time-input" defaultValue={task_time} onChange={(e) => setTaskTime(e.target.value)}></input>
                                <button type="submit" className="btn btn_green task__time-submit" onClick={changeTime}>OK</button>
                            </div> :
                            <span onClick={() => toggleShowTimeForm(true)}>{task_time}</span>
                        } minutes
                    </div>
                )}

                {
                    !task.routine && !done && (
                        <div className={show_form ? "task__rename" : "task__rename visible"}>
                            <button onClick={() => setShowForm(true)} className="btn btn_transparent routines__close">Rename</button>
                        </div>
                    )
                }


                <div onClick={deleteTask} className={(done || !day_active || show_form) ? "task__delete" : "task__delete visible"}>
                    <button onClick={deleteCallback} className="btn btn_transparent routines__close"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.0625 5.625H3.9375V15.1875C3.9375 15.8091 4.44094 16.3125 5.0625 16.3125H12.9375C13.5591 16.3125 14.0625 15.8091 14.0625 15.1875V5.625Z" stroke="#CB6217" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path><path d="M11.25 3.9375H6.75V2.8125C6.75 2.19094 7.25344 1.6875 7.875 1.6875H10.125C10.7466 1.6875 11.25 2.19094 11.25 2.8125V3.9375Z" stroke="#CB6217" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path><path d="M15.75 5.625H2.25V4.5C2.25 4.1895 2.502 3.9375 2.8125 3.9375H15.1875C15.498 3.9375 15.75 4.1895 15.75 4.5V5.625Z" stroke="#CB6217" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path><path d="M7.3125 9L10.6875 12.375" stroke="#CB6217" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path><path d="M7.3125 12.375L10.6875 9" stroke="#CB6217" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path></svg></button>
                </div>

            </div>
        </div>
    );
}

export default TaskItem;