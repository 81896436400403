import React, { useState, useEffect, useCallback, ReactEventHandler } from "react";
import { IRoutine, ITask } from "../interfaces/interfaces";
import authHeader from "../services/auth-header";
import { fetchRoutines, fetchTasks } from "../services/q";
import {
  formatToYMD,
  formatToString,
  addDay,
  deductDay,
} from "../services/dates";
import axios from "axios";
import RoutineForm from "../components/forms/RoutineForm";
import RoutineItem from "../components/lists/RoutineItem";
import TaskItem from "../components/lists/TaskItem";
import { confirmAlert } from 'react-confirm-alert';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { debounce } from "lodash"

const random_emojis = require("../assets/emojis.json");

const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

//import { DragDropContext } from 'react-beautiful-dnd';

const API_URL = "https://krgb.velichko.co";

const Tasks = (props: any) => {
  let [is_error, setError] = useState(false);
  let [routines, setRoutines] = useState<Array<IRoutine>>([]);
  let [today_routines, setTodayRoutines] = useState<Array<IRoutine>>([]);
  let [day, setDay] = useState(new Date());
  let [textDay, setTextDay] = useState(formatToString(day));
  let [can_be_tasks_added, setCanBeTasksAdded] = useState(true);
  let [task_adding_active, setTaskAddingActive] = useState(false);
  let [new_nonroutine_task, setNewNonroutineTask] = useState("");
  const today = new Date();

  let [tasks, setTasks] = useState<Array<ITask>>([]);

  const addNonRoutineTask = async (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    if (new_nonroutine_task.length>2) {
      axios
      .post(
        API_URL + "/tasks/createNR",
        {
          date: formatToYMD(day),
          task: {
            text: new_nonroutine_task
          },
        },
        { headers: authHeader() }
      )
      .then(function (response) {
        let new_task = response.data;
        setTasks([...tasks, new_task]);
        setTaskAddingActive(false);
        setNewNonroutineTask("");
      })
      .catch(function (error) {
        console.log(error);
      });
    }
    
  }

  const addRoutineToTasks = async (index: number) => {
    //, e: React.ChangeEvent<any>
    //e.preventDefault();
    let changed_routine = routines[index];
    changed_routine.added = true;
    let changed_routines = [...routines, changed_routine];
    let routine_name = changed_routine.name;
    console.log(changed_routine, "changed_routine")
    setRoutines(changed_routines);
    // axios add task
    // add returned task to tasks
    axios
      .post(
        API_URL + "/tasks/create",
        {
          date: formatToYMD(day),
          task: {
            routineId: changed_routine.id,
            text: routine_name,
            time: changed_routine.time
          },
        },
        { headers: authHeader() }
      )
      .then(function (response) {
        console.log(response);
        let new_task = response.data;
        new_task.time = changed_routine.time
        new_task.routine = { name: changed_routine.name };
        setTasks([...tasks, new_task]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const updateTasksRoutines = async () => {
    Promise.all([fetchRoutines(), fetchTasks(formatToYMD(day))])
      .then(function (results) {
        let routines = results[0];
        let tasks = results[1];
        tasks.sort((a: any, b: any) => a.order - b.order);

        setRoutines(routines);
        setTasks(tasks);
        let filtered_routines = routines;
        tasks.forEach((task: any) => {
          if (task.routineId != null) {
            let task_routine = filtered_routines.find(
              (el: any) => el.id == task.routineId
            );
            if (task_routine) task_routine.added = true;
          }
        });
        setTodayRoutines(filtered_routines);
      })
      .catch(function (err) {
        setError(true);
      });
  };

  const changeDay = (operation: string) => {
    setTasks([])
    let new_day = day;
    if (operation == "add") addDay(day);
    else new_day = deductDay(day);
    setDay(new_day);
    let text_day = formatToString(day)
    setTextDay(text_day);
    updateTasks(new_day);
    let new_day_raw = new Date(new_day);
    if (today.getTime() - new_day_raw.getTime() > 26784000)
      setCanBeTasksAdded(false);
    else setCanBeTasksAdded(true);
    document.title = `My tasks for ${text_day[1]} | Kerge.lt`;
  };

  const updateTasks = async (day: any) => {
    fetchTasks(formatToYMD(day))
      .then((result) => {
        let tasks = result;
        setTasks(tasks);
        console.log(tasks);
        setTodayRoutines(routines);
        let filtered_routines = today_routines;
        filtered_routines.forEach((routine: any) => {
          routine.added = false;
        });
        setTodayRoutines(filtered_routines);
        tasks.forEach((task: any) => {
          if (task.routineId != null) {
            let task_routine = filtered_routines.find(
              (el: any) => el.id == task.routineId
            );
            if (task_routine) task_routine.added = true;
          }
        });
        setTodayRoutines(filtered_routines);
      })
      .catch(() => {
        setError(true);
      });
  };

  const switchTaskStatus = (i: number) => {
    debounceSwitchTaskStatus(i, tasks)
  };

  const debounceSwitchTaskStatus = useCallback(debounce(async (i, tasks) => {
    console.log(tasks)
    let new_tasks = tasks;
    let task = new_tasks[i];
    console.log(task)
      let result = await axios
      .post(
        API_URL + "/tasks/switch",
        { id: task.id, status: task.done },
        { headers: authHeader() }
      )
      .then((response) => {
        console.log(response.data);
        new_tasks[i] = response.data;
        setTasks(new_tasks);
      })
      .catch((err) => {
        setError(true);
        throw new Error(err);
      });
  }, 500), []);

  const changeTaskTime = async (time: number, i: number) => {
    let new_tasks = tasks;
    let task = new_tasks[i];
    let result = await axios
    .post(
      API_URL + "/tasks/time",
      { id: task.id, time: time },
      { headers: authHeader() }
    )
    .then((response) => {
      new_tasks[i] = response.data;
      setTasks(new_tasks);
    })
    .catch((err) => {
      setError(true);
      throw new Error(err);
    });
  }

  const deleteTask = async (i: number) => {
    // delete from the list
    // delete from the back
    let task = tasks[i];
    let id = task.id;
    console.log(id)
    let result = await axios.delete(API_URL + `/tasks/${id}`, { headers: authHeader() })
      .then((response) => {
        const new_tasks = tasks.filter((task: any) => task.id !== id);
        setTasks(new_tasks);
      })
      .catch((err) => {
        console.log(err);
        setError(true)
      })
  }

  const confirmDeleteTask = (i: number) => {
    confirmAlert({
      title: 'Delete a task',
      message: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteTask(i)
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });
  }

  const renameTask = async (text: string, i: number) => {
    console.log("renameTask")
    let task = tasks[i]
    let id = task.id
    let result = await axios.put(API_URL + `/tasks/${id}`, { text }, { headers: authHeader() })
      .then((response) => {
        let new_tasks = tasks;
        new_tasks[i] = response.data;
        setTasks(new_tasks);
      })
      .catch((err) => {
        console.log(err);
        setError(true)
      });

  }

  const handleDragEvent = async (drag_obj:any) => {
    if (!drag_obj.destination) return;
    let new_index = drag_obj.destination.index;
    let new_array = tasks;
    new_array.splice(new_index, 0, new_array.splice(drag_obj.source.index, 1)[0]);
    
    const saveOrder = async (arr: any) => {
      
      let result = await axios.post(API_URL + "/tasks/order", {tasks: arr}, { headers: authHeader() })
        .then((response) => {
          return true
        })
        .catch((err) => {
          console.log(err);
          setError(true);
          return false;
        });

       return result;
    }

    if (new_index == 0) {
      // check order of the next task
      // -1 it
      new_array[0].order = new_array[1].order - 1;
      // Save only current
      saveOrder([new_array[0]]);
    }
    else if (new_index == tasks.length - 1) {
      // check order of the previous task
      // +1 it
      new_array[new_index].order = new_array[new_index - 1].order + 1;
      // Save only current
      saveOrder([new_array[new_index]]);
    }
    else {
      // = чекнуть следующий и предыдущий
      // - если они не равны
      //   - если есть промежуток
      //     = то сделать order между ними
      //   - если нет промежутка
      //     = сделать order всех элементов
      // - если они равны
      //   = сделать ордер всех элементов
      const prev_task = new_array[new_index - 1];
      const next_task = new_array[new_index + 1];
      const prev_order = prev_task.order;
      const next_order = next_task.order;
      if (next_order - prev_order < 2) {
        // сделать ордер всех элементов
        new_array.forEach((task: ITask, i: number) => {
          task.order = (i+1)*5;
        });
        // Save all
        saveOrder(new_array);
      }
      else {
        // сделать order между ними
        const new_order = Math.round((prev_order + next_order) / 2);
        new_array[new_index].order = new_order;
        // Save prev, cur, next
        saveOrder([prev_task, new_array[new_index], next_task]);
      }
    }

    console.log(new_array)
    setTasks(new_array);

  }

  const getDraggableId = (id: number|undefined) => {
    if (id == undefined) return "-1";
    else return id.toString();
  }

  useEffect(() => {
    document.title = 'My tasks for today | Kerge.lt';
    if (localStorage.getItem("user") == null) {
      window.location.replace("/login");
    }
    updateTasksRoutines();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="page-header tasks__page-header">
            <div className="tasks__arrow" onClick={() => changeDay("deduct")}>
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.75 8.11406L23.2945 6.75L11.25 18L23.2945 29.25L24.75 27.893L14.168 18L24.75 8.11406Z"
                  fill="#111111"
                />
              </svg>
            </div>
            <h1 className="tasks__header">
              {textDay[0]}
              <span>, {textDay[1]}</span>
            </h1>
            <div className="tasks__arrow" onClick={() => changeDay("add")}>
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.25 27.8859L12.7055 29.25L24.75 18L12.7055 6.75L11.25 8.10703L21.832 18L11.25 27.8859Z"
                  fill="#111111"
                />
              </svg>
            </div>
          </div>

          {is_error ? (
            <div className="alert alert-danger tasks__alert" role="alert">
              🚩 We're sorry, something went wrong
            </div>
          ) : (
            ""
          )}



          <div className="block block_nopadding tasks__block tasks">

            {tasks.length ? (
              <DragDropContext onDragEnd={(e:any) => handleDragEvent(e)}>
                <Droppable droppableId="tasks">
                  {(provided: any) => (
                    <div className="tasks__list" {...provided.droppableProps} ref={provided.innerRef}>
                      {tasks.map((task, index) => (
                        <Draggable key={task.id} draggableId={getDraggableId(task.id)} index={index}>
                          {(provided) => (
                            <TaskItem
                            provided={provided}
                            key={task.id}
                            task={task}
                            switchCallback={() => {console.log(1); switchTaskStatus(index)}}
                            deleteCallback={() => confirmDeleteTask(index)}
                            renameTaskCallback={renameTask}
                            changeTimeCallback={changeTaskTime}
                            day_active={can_be_tasks_added}
                            index={index}
                          />

                          )}
                          
                          </Draggable>
                      ))}
                      {provided.placeholder}
                      </div>
                  )}
                </Droppable></DragDropContext>
            ) : (

              <div className={"no-tasks " + (task_adding_active ? 'hidden' : '')} >
                🙈 You have no added tasks for this day
              </div>
            )}
            {can_be_tasks_added ?
              <div className="tasks__add">
                {task_adding_active ?
                  <div className="task_add-form">
                    <input placeholder="Non-routine task" type="text" className="tasks__add-input" value={new_nonroutine_task} onChange={(e) => setNewNonroutineTask(e.target.value)} />
                    <input type="submit" disabled={new_nonroutine_task.length<2} className="btn btn_green tasks__save-button" value="Add" onClick={addNonRoutineTask} />
                    <a onClick={() => setTaskAddingActive(false)} href="#" className="btn btn_transparent routines__close"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.963 9.57107L7.39189 5.99998L10.963 2.42888C11.3485 2.04333 11.3485 1.42263 10.963 1.03708C10.5774 0.651538 9.95674 0.651538 9.57119 1.03708L6.0001 4.60818L2.429 1.03708C2.04346 0.651538 1.42275 0.651538 1.03721 1.03708C0.65166 1.42263 0.65166 2.04333 1.03721 2.42888L4.6083 5.99998L1.03721 9.57107C0.65166 9.95662 0.65166 10.5773 1.03721 10.9629C1.42275 11.3484 2.04346 11.3484 2.429 10.9629L6.0001 7.39177L9.57119 10.9629C9.95674 11.3484 10.5774 11.3484 10.963 10.9629C11.3458 10.5773 11.3458 9.95388 10.963 9.57107Z" fill="#969EA3" fillOpacity="0.51"></path></svg></a>
                  </div>
                  : <div className="tasks__add-button" onClick={() => setTaskAddingActive(true)}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.75 12H20.25" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M12 3.75V20.25" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>}
              </div>
              : ""}

          </div>

          {today_routines.length ? <div
            className={
              "tasks__routines " + (can_be_tasks_added ? "active" : "disabled")
            }
          >
            {today_routines.map((routine, index) => (
              routine.active ?
                <div
                  className={"task__routine " + (routine.added ? "added" : "")}
                  key={routine.id}
                  onClick={() => addRoutineToTasks(index)}
                >
                  {routine.name}
                </div> : ""
            ))}
          </div> :
            <div className="no-routines">
              You currently have no routines. Add them in the <a href="/routines">routines page</a>
            </div>
          }


        </div>
      </div>
    </div>
  );
};

export default Tasks;
