import { Link, useLocation } from "react-router-dom";

// redux
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { removeUserData, setUserData, selectUserData } from '../stores/userData/userSlice';
import AuthService from "../services/auth.service";

// components
import LoggedIn from "./navigation/LoggedIn";
import Unlogged from "./navigation/Unlogged";
import { useEffect } from 'react';

function NavigationBar(props: any) {

  const userData = useAppSelector(selectUserData);
  const dispatch = useAppDispatch();
  const location = useLocation().pathname.split("/");

  const whiteHeaderPages = ["routines", "tasks"]

  useEffect(() => {

    console.log(location[1]);
  })

  return (
    <div className={props.user ? 'navbar logged' : 'navbar unlogged'}>
      <div className="container">
        {props.user ?
          <LoggedIn />
          :
          <Unlogged />
        }
      </div>

    </div>
  );
}

export default NavigationBar;