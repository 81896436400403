export const validateEmail = (email: string) => {
    let re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export const validatePassword = (password: string) => {
    //let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    //return re.test(password);
    return password.length >= 6;
}

const validatePasswords = (password: string, password2: string) => {
    return password === password2;
};

const validateUsername = (username: string) => {
    let re = /^[a-zA-Z0-9]{3,}$/;
    return re.test(username);
}

export const validateUserData = (data: any) => {
    console.log(`Validating user data: ${data}`);
    let {username, email} = data
    return validateUsername(username) && validateEmail(email);
}

export const validateNewPassword = (password:string, password2: string) => {
    return validatePassword(password) && validatePasswords(password, password2);
}