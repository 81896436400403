import React, { useState, useEffect, ReactEventHandler } from "react";
import {formatToShortString} from "../services/dates";

function ActivityLine(props: any) {
    const { activity } = props;

    let sessions = [];
    let now = new Date();
    let today = new Date(now.toDateString())
    let today_int = today.getTime();

    for (let i = 1; i <= 28; i++) {
        const date = new Date(today_int - (28 - i) * 24 * 60 * 60 * 1000);
        let completed_session = activity.tasks.find((session: any) => {
            let session_date = new Date(session.date);
            return session_date.toDateString() === date.toDateString();
        });

        if (completed_session) {
            sessions.push({...completed_session, active: true});
        }
        else sessions.push({
            date,
            active: false
        });
    }

    return (
        <div className="activity-line">
            <h3 className="activity-line__header">{activity.icon} {activity.name}</h3>
            <div className="activity-line__activities">
                {sessions.map((session: any) => (
                    <div key={session.date} className={`activity-line__activity ${session.active? "active" :"" }` }>
                        <div className="activity-line__info">
                            <h5>{formatToShortString(session.date)}</h5>
                            <div>{session.time} minutes</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ActivityLine;