import React, { useState, useEffect, ReactEventHandler } from "react";
import EmojiPicker from 'emoji-picker-react';


function RoutineForm(props: any) {

    const { routine, button_text, cancelCallback, saveCallback, toggleForm } = props;
    let [show_emoji_form, toggleEmojiForm] = useState(false);
    let [form_routine, setRoutine] = useState(routine);

    const formSubmit = (e: React.FormEvent<EventTarget>) => {
        e.preventDefault();
        saveCallback(form_routine)
    }

    const closeForm = (e: React.FormEvent<EventTarget>) => {
        e.preventDefault();
        cancelCallback()
    }

    return (
        <form className="routines__form" onSubmit={formSubmit}>
            <div className="routines__icon" onClick={() => toggleEmojiForm(true)}>{form_routine.icon}</div>
            <input placeholder="Name routine" type="text" value={form_routine.name} onChange={(e) => setRoutine({ ...form_routine, name: e.target.value })} className="routines__input" />
            <input type="submit" className="btn btn_green routines__button" value={button_text} disabled={form_routine.name.length < 3} />
            <a href="#" className="btn btn_transparent routines__close" onClick={closeForm}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.963 9.57107L7.39189 5.99998L10.963 2.42888C11.3485 2.04333 11.3485 1.42263 10.963 1.03708C10.5774 0.651538 9.95674 0.651538 9.57119 1.03708L6.0001 4.60818L2.429 1.03708C2.04346 0.651538 1.42275 0.651538 1.03721 1.03708C0.65166 1.42263 0.65166 2.04333 1.03721 2.42888L4.6083 5.99998L1.03721 9.57107C0.65166 9.95662 0.65166 10.5773 1.03721 10.9629C1.42275 11.3484 2.04346 11.3484 2.429 10.9629L6.0001 7.39177L9.57119 10.9629C9.95674 11.3484 10.5774 11.3484 10.963 10.9629C11.3458 10.5773 11.3458 9.95388 10.963 9.57107Z" fill="#969EA3" fillOpacity="0.51" />
                </svg>
            </a>
            {show_emoji_form ?
                <div id="emojiModal" className="modal">
                    <div className="modal-content emoji__modal-content">
                        <div className="modal-header">
                            <h4>Select emoji icon</h4>
                            <span className="close" onClick={() => toggleEmojiForm(false)}>&times;</span>
                        </div>
                        {<EmojiPicker width="100%" onEmojiClick={(emojiData: any, event: MouseEvent) => {
                            setRoutine({ ...form_routine, icon: emojiData.emoji });
                            toggleEmojiForm(false)
                        }} />}
                    </div>
                </div> : ""}
        </form>

    );
}

export default RoutineForm;