import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { IUser, ICategory } from '../../interfaces/interfaces';

export interface UserDataState {
  token: string | undefined;
  id: number | undefined,
  email?: string | undefined,
  name?: string | undefined,
  avatar?: string | undefined,
  settings_use_emojis?: boolean,
  settings_use_dark_theme?: boolean,
  oauth_login?: string | undefined,
  type?: string | undefined,
  categories?: Array<ICategory>
}

const initialState: UserDataState = {
  token: undefined,
  id: undefined,
  email: undefined,
  name: undefined,
  avatar: undefined,
  settings_use_emojis: true,
  settings_use_dark_theme: false,
  oauth_login: undefined,
  type: undefined,
  categories: []
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    removeUserData: (state) => {
      state = {
        token: undefined,
        id: undefined,
        email: undefined,
        name: undefined,
        avatar: undefined,
        oauth_login: undefined,
        type: undefined,
        settings_use_emojis: true,
        settings_use_dark_theme: false,
        categories: []
      };
    },
    setUserData: (state, action: PayloadAction<UserDataState>) => {
      state.token = action.payload.token;
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.settings_use_dark_theme = action.payload.settings_use_dark_theme;
      state.categories = action.payload.categories;
      state.type = action.payload.type;
      state.oauth_login = action.payload.oauth_login;
      state.avatar = action.payload.avatar;
    },
    setUserSettings: (state, action: PayloadAction<UserDataState>) => {
      state.token = action.payload.token;
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.settings_use_dark_theme = action.payload.settings_use_dark_theme;
      state.categories = action.payload.categories;
      state.type = action.payload.type;
      state.oauth_login = action.payload.oauth_login;
      state.avatar = action.payload.avatar;
    },
    switchTheme: (state) => {
      state.settings_use_dark_theme = !state.settings_use_dark_theme;
    }
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUserData = (state: RootState) => state.userData;

export const { removeUserData, setUserData, setUserSettings, switchTheme } = userSlice.actions;

export default userSlice.reducer;
