import React, { useEffect, useState } from "react";
import AuthService from "../services/auth.service";
import { useNavigate } from "react-router-dom";

import { validateUserData, validatePassword } from '../services/validators';

import { useAppSelector, useAppDispatch } from '../app/hooks';
import { removeUserData, setUserData, selectUserData } from '../stores/userData/userSlice';

const Signup = () => {
  const navigate = useNavigate();
  const email = new URLSearchParams(window.location.search).get("email");
  let [user, setUser] = useState({ email: email||"", name: "", password: "" })
  let [error, setError] = useState(false);
  const userData = useAppSelector(selectUserData);
  const dispatch = useAppDispatch();

  

  const registerUser = async (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    if (validateUserData(user) && validatePassword(user.password)) {
      try {
        await AuthService.signup(user.email, user.name, user.password).then(
          (response: any) => {
            dispatch(setUserData({ id: response.id, token: response.token }));
            navigate("/");
            window.location.reload();
          },
          (error: any) => {
            console.log(error);
            setError(true);
          }
        );
      } catch (err) {
        console.log(err);
        setError(true);
      }
    }
    else {
      setError(true);
    }

  };

  useEffect(() => {
    document.title = 'Sign up | Kerge.lt';
  }, []);

  return (
    <div className="login-page">
      <div className="container">
        <div className="row">
          <div className="login-container">
            <h2>Sign up</h2>
            <form onSubmit={registerUser}>
              {error ?
                <div className="alert alert-danger" role="alert">There's some error</div>
                : ""}
              <div className="form-group">
                <input type="text"
                  value={user.name}
                  className="form-control"
                  placeholder="Name"
                  onChange={e => setUser({ ...user, name: e.target.value.toLowerCase() })}
                />
              </div>
              <div className="form-group">
                <input type="text"
                  value={user.email}
                  className="form-control"
                  placeholder="Email"
                  onChange={e => setUser({ ...user, email: e.target.value.toLowerCase() })}
                />
              </div>
              <div className="form-group">
                <input type="password"
                  value={user.password}
                  placeholder="Password"
                  className="form-control"
                  onChange={e => setUser({ ...user, password: e.target.value })}
                />
              </div>
              <button className="btn btn_green login__btn" type="submit">Sign up</button>
            </form>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Signup;
