import { advisorynotifications_v1 } from 'googleapis';
import React from 'react';

const Logo = (props: any) => {
    const { theme } = props;

    return (
        <div className="logo-container">
            {theme ? (
                <svg className="logo logo_dark" width="133" height="38" viewBox="0 0 133 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="logo__rect" d="M32.5106 5.26154C32.5106 3.32429 31 2.5 29.0274 1.75385C29.0274 1.75385 20.9878 0 16 0C11.0122 0 3.48328 1.75385 3.48328 1.75385C1 2.5 0 3.32429 0 5.26154V25.7231C0 27.6603 1.55952 29.2308 3.48328 29.2308H29.0274C30.9511 29.2308 32.5106 27.6603 32.5106 25.7231V5.26154Z" fill="#4E4D82" />
                    <path  d="M17.3368 37.2135C16.952 38.205 15.5587 38.205 15.1739 37.2135L5.81338 13.0948C5.51878 12.3358 5.9904 12.0066 6.86809 11.515C6.88574 11.5051 6.90083 11.4961 6.91793 11.4853C7.30602 11.2399 12.5005 8 16.2553 8C20.0159 8 25.2204 11.2497 25.5945 11.4864C25.6101 11.4963 25.6241 11.5047 25.6402 11.5138C26.5091 12.0061 26.9921 12.3352 26.6973 13.0948L17.3368 37.2135Z" fill="#FFBE00" />
                    <path d="M17.3368 37.2135C16.952 38.205 15.5587 38.205 15.1739 37.2135L5.81338 13.0948C5.51878 12.3358 5.9904 12.0066 6.86809 11.515C6.88574 11.5051 6.90083 11.4961 6.91793 11.4853C7.30602 11.2399 12.5005 8 16.2553 8C20.0159 8 25.2204 11.2497 25.5945 11.4864C25.6101 11.4963 25.6241 11.5047 25.6402 11.5138C26.5091 12.0061 26.9921 12.3352 26.6973 13.0948L17.3368 37.2135Z" fill="url(#paint0_linear_1103_813)" fillOpacity="0.2" />
                    <path className="logo__letter" id="logo__d" d="M121.72 23.26C120.733 23.26 119.833 23.0333 119.02 22.58C118.22 22.1133 117.58 21.4667 117.1 20.64C116.62 19.8 116.38 18.8 116.38 17.64C116.38 16.4933 116.62 15.5067 117.1 14.68C117.58 13.84 118.22 13.1933 119.02 12.74C119.833 12.2867 120.733 12.06 121.72 12.06C122.653 12.06 123.44 12.26 124.08 12.66C124.733 13.06 125.227 13.6733 125.56 14.5C125.893 15.3267 126.06 16.3733 126.06 17.64C126.06 18.9333 125.9 19.9933 125.58 20.82C125.26 21.6467 124.78 22.26 124.14 22.66C123.5 23.06 122.693 23.26 121.72 23.26ZM122.52 20.24C122.947 20.24 123.327 20.14 123.66 19.94C124.007 19.74 124.28 19.4467 124.48 19.06C124.68 18.66 124.78 18.1867 124.78 17.64C124.78 17.0933 124.68 16.6333 124.48 16.26C124.28 15.8733 124.007 15.58 123.66 15.38C123.327 15.18 122.947 15.08 122.52 15.08C122.08 15.08 121.687 15.18 121.34 15.38C121.007 15.58 120.74 15.8733 120.54 16.26C120.34 16.6333 120.24 17.0933 120.24 17.64C120.24 18.1867 120.34 18.66 120.54 19.06C120.74 19.4467 121.007 19.74 121.34 19.94C121.687 20.14 122.08 20.24 122.52 20.24ZM125.9 23.08C125.348 23.08 124.9 22.6323 124.9 22.08V21.22L124.92 17.64L124.72 14.06V9.24C124.72 8.68772 125.168 8.24001 125.72 8.24001H127.52C128.072 8.24001 128.52 8.68772 128.52 9.24001V22.08C128.52 22.6323 128.072 23.08 127.52 23.08H125.9Z" fill="#4E4D82" />
                    <path className="logo__letter" id="logo__r" d="M110 23.08C109.448 23.08 109 22.6323 109 22.08V13.24C109 12.6877 109.448 12.24 110 12.24H111.935C112.313 12.24 112.62 12.5469 112.62 12.9254V14.4764C112.62 14.7359 112.278 14.8305 112.145 14.608V14.608C112.104 14.5399 112.097 14.4564 112.128 14.3834C112.451 13.6284 112.955 13.0606 113.64 12.68C114.115 12.4076 114.656 12.2249 115.264 12.132C115.809 12.0486 116.26 12.5077 116.26 13.06V15.0178C116.26 15.2608 116.043 15.44 115.8 15.44V15.44C115.68 15.4267 115.547 15.42 115.4 15.42C114.627 15.42 114 15.6333 113.52 16.06C113.04 16.4733 112.8 17.1533 112.8 18.1V22.08C112.8 22.6323 112.352 23.08 111.8 23.08H110Z" fill="#4E4D82" />
                    <path className="logo__letter" id="logo__i2" d="M105 23.08C104.448 23.08 104 22.6323 104 22.08V13.24C104 12.6877 104.448 12.24 105 12.24H106.8C107.352 12.24 107.8 12.6877 107.8 13.24V22.08C107.8 22.6323 107.352 23.08 106.8 23.08H105ZM105.9 11.04C105.207 11.04 104.647 10.8467 104.22 10.46C103.793 10.0733 103.58 9.59333 103.58 9.02C103.58 8.44667 103.793 7.96667 104.22 7.58C104.647 7.19333 105.207 7 105.9 7C106.593 7 107.153 7.18667 107.58 7.56C108.007 7.92 108.22 8.38667 108.22 8.96C108.22 9.56 108.007 10.06 107.58 10.46C107.167 10.8467 106.607 11.04 105.9 11.04Z" fill="#4E4D82" />
                    <path className="logo__letter" id="logo__b2" d="M97.82 23.26C96.8467 23.26 96.0333 23.06 95.38 22.66C94.74 22.26 94.26 21.6467 93.94 20.82C93.62 19.9933 93.46 18.9333 93.46 17.64C93.46 16.3733 93.6267 15.3267 93.96 14.5C94.3067 13.6733 94.8067 13.06 95.46 12.66C96.1133 12.26 96.9 12.06 97.82 12.06C98.8067 12.06 99.7 12.2867 100.5 12.74C101.313 13.1933 101.953 13.84 102.42 14.68C102.9 15.5067 103.14 16.4933 103.14 17.64C103.14 18.8 102.9 19.8 102.42 20.64C101.953 21.4667 101.313 22.1133 100.5 22.58C99.7 23.0333 98.8067 23.26 97.82 23.26ZM92 23.08C91.4477 23.08 91 22.6323 91 22.08V9.24001C91 8.68772 91.4477 8.24001 92 8.24001H93.8C94.3523 8.24001 94.8 8.68772 94.8 9.24001V14.06L94.6 17.64L94.62 21.22V22.08C94.62 22.6323 94.1723 23.08 93.62 23.08H92ZM97.02 20.24C97.4467 20.24 97.8267 20.14 98.16 19.94C98.5067 19.74 98.78 19.4467 98.98 19.06C99.1933 18.66 99.3 18.1867 99.3 17.64C99.3 17.0933 99.1933 16.6333 98.98 16.26C98.78 15.8733 98.5067 15.58 98.16 15.38C97.8267 15.18 97.4467 15.08 97.02 15.08C96.5933 15.08 96.2067 15.18 95.86 15.38C95.5267 15.58 95.2533 15.8733 95.04 16.26C94.84 16.6333 94.74 17.0933 94.74 17.64C94.74 18.1867 94.84 18.66 95.04 19.06C95.2533 19.4467 95.5267 19.74 95.86 19.94C96.2067 20.14 96.5933 20.24 97.02 20.24Z" fill="#4E4D82" />
                    <path className="logo__letter" id="logo__t" d="M86.84 23.26C85.4533 23.26 84.3733 22.92 83.6 22.24C82.8267 21.5467 82.44 20.5067 82.44 19.12V10.82C82.44 10.2677 82.8877 9.82001 83.44 9.82001H85.24C85.7923 9.82001 86.24 10.2677 86.24 10.82V19.08C86.24 19.4667 86.3467 19.7733 86.56 20C86.7733 20.2133 87.0467 20.32 87.38 20.32V20.32C87.9962 20.32 88.7441 20.5575 88.9494 21.1385L89.2207 21.9064C89.3732 22.3378 89.2144 22.8304 88.78 22.9744C88.6297 23.0242 88.4697 23.0661 88.3 23.1C87.8333 23.2067 87.3467 23.26 86.84 23.26ZM81.86 15.48C81.3077 15.48 80.86 15.0323 80.86 14.48V13.64C80.86 13.0877 81.3077 12.64 81.86 12.64H87.72C88.2723 12.64 88.72 13.0877 88.72 13.64V14.48C88.72 15.0323 88.2723 15.48 87.72 15.48H81.86Z" fill="#4E4D82" />
                    <path className="logo__letter" id="logo__i1" d="M77 23.08C76.4477 23.08 76 22.6323 76 22.08V13.24C76 12.6877 76.4477 12.24 77 12.24H78.8C79.3523 12.24 79.8 12.6877 79.8 13.24V22.08C79.8 22.6323 79.3523 23.08 78.8 23.08H77ZM77.9 11.04C77.2067 11.04 76.6467 10.8467 76.22 10.46C75.7933 10.0733 75.58 9.59333 75.58 9.02C75.58 8.44667 75.7933 7.96667 76.22 7.58C76.6467 7.19333 77.2067 7 77.9 7C78.5933 7 79.1533 7.18667 79.58 7.56C80.0067 7.92 80.22 8.38667 80.22 8.96C80.22 9.56 80.0067 10.06 79.58 10.46C79.1667 10.8467 78.6067 11.04 77.9 11.04Z" fill="#4E4D82" />
                    <path className="logo__letter" id="logo__b1" d="M69.82 23.26C68.8467 23.26 68.0333 23.06 67.38 22.66C66.74 22.26 66.26 21.6467 65.94 20.82C65.62 19.9933 65.46 18.9333 65.46 17.64C65.46 16.3733 65.6267 15.3267 65.96 14.5C66.3067 13.6733 66.8067 13.06 67.46 12.66C68.1133 12.26 68.9 12.06 69.82 12.06C70.8067 12.06 71.7 12.2867 72.5 12.74C73.3133 13.1933 73.9533 13.84 74.42 14.68C74.9 15.5067 75.14 16.4933 75.14 17.64C75.14 18.8 74.9 19.8 74.42 20.64C73.9533 21.4667 73.3133 22.1133 72.5 22.58C71.7 23.0333 70.8067 23.26 69.82 23.26ZM64 23.08C63.4477 23.08 63 22.6323 63 22.08V9.24001C63 8.68772 63.4477 8.24001 64 8.24001H65.8C66.3523 8.24001 66.8 8.68772 66.8 9.24001V14.06L66.6 17.64L66.62 21.22V22.08C66.62 22.6323 66.1723 23.08 65.62 23.08H64ZM69.02 20.24C69.4467 20.24 69.8267 20.14 70.16 19.94C70.5067 19.74 70.78 19.4467 70.98 19.06C71.1933 18.66 71.3 18.1867 71.3 17.64C71.3 17.0933 71.1933 16.6333 70.98 16.26C70.78 15.8733 70.5067 15.58 70.16 15.38C69.8267 15.18 69.4467 15.08 69.02 15.08C68.5933 15.08 68.2067 15.18 67.86 15.38C67.5267 15.58 67.2533 15.8733 67.04 16.26C66.84 16.6333 66.74 17.0933 66.74 17.64C66.74 18.1867 66.84 18.66 67.04 19.06C67.2533 19.4467 67.5267 19.74 67.86 19.94C68.2067 20.14 68.5933 20.24 69.02 20.24Z" fill="#4E4D82" />
                    <path className="logo__letter" id="logo__a" d="M59.62 23.08C59.0677 23.08 58.62 22.6323 58.62 22.08V21.3045C58.62 21.1438 58.5813 20.9856 58.5072 20.8431L58.4728 20.7769C58.3987 20.6344 58.36 20.4761 58.36 20.3155V16.84C58.36 16.24 58.1733 15.78 57.8 15.46C57.44 15.1267 56.86 14.96 56.06 14.96C55.54 14.96 55.0133 15.0467 54.48 15.22C54.3825 15.2492 54.2878 15.2807 54.1956 15.3144C53.5999 15.5323 52.8553 15.3665 52.5734 14.7983L52.2351 14.1164C52.0098 13.6622 52.1579 13.1044 52.6182 12.8919C53.0479 12.6935 53.5218 12.5295 54.04 12.4C54.9067 12.1733 55.7667 12.06 56.62 12.06C58.38 12.06 59.74 12.4667 60.7 13.28C61.6733 14.08 62.16 15.34 62.16 17.06V22.08C62.16 22.6323 61.7123 23.08 61.16 23.08H59.62ZM55.42 23.26C54.5533 23.26 53.82 23.1133 53.22 22.82C52.62 22.5267 52.16 22.1267 51.84 21.62C51.5333 21.1133 51.38 20.5467 51.38 19.92C51.38 19.2533 51.5467 18.68 51.88 18.2C52.2267 17.7067 52.7533 17.3333 53.46 17.08C54.1667 16.8133 55.08 16.68 56.2 16.68H57.77C58.3168 16.68 58.76 17.1232 58.76 17.67V17.67C58.76 18.2168 58.3168 18.66 57.77 18.66H56.72C56.1067 18.66 55.6733 18.76 55.42 18.96C55.18 19.16 55.06 19.4267 55.06 19.76C55.06 20.0933 55.1867 20.36 55.44 20.56C55.6933 20.76 56.04 20.86 56.48 20.86C56.8933 20.86 57.2667 20.76 57.6 20.56V20.56C57.9671 20.3341 58.6444 20.3657 58.7945 20.7698L58.8247 20.8511C58.8607 20.948 58.8666 21.054 58.8357 21.1526C58.6246 21.8243 58.246 22.3401 57.7 22.7C57.1267 23.0733 56.3667 23.26 55.42 23.26Z" fill="#4E4D82" />
                    <path className="logo__letter" id="logo__h" d="M46.06 12.06C46.9 12.06 47.66 12.2333 48.34 12.58C49.02 12.9133 49.5533 13.4333 49.94 14.14C50.34 14.8467 50.54 15.76 50.54 16.88V22.08C50.54 22.6323 50.0923 23.08 49.54 23.08H47.74C47.1877 23.08 46.74 22.6323 46.74 22.08V17.5C46.74 16.7267 46.58 16.1667 46.26 15.82C45.9533 15.46 45.52 15.28 44.96 15.28C44.56 15.28 44.1933 15.3733 43.86 15.56C43.5267 15.7333 43.2667 16.0067 43.08 16.38C42.8933 16.7533 42.8 17.24 42.8 17.84V22.08C42.8 22.6323 42.3523 23.08 41.8 23.08H40C39.4477 23.08 39 22.6323 39 22.08V9.24001C39 8.68772 39.4477 8.24001 40 8.24001H41.8C42.3523 8.24001 42.8 8.68772 42.8 9.24001V14.3492C42.8 14.7039 42.3693 14.8795 42.1213 14.6259V14.6259C42.0013 14.5031 41.9727 14.3161 42.0612 14.169C42.4591 13.5074 42.9788 13.0044 43.62 12.66C44.34 12.26 45.1533 12.06 46.06 12.06Z" fill="#4E4D82" />
                </svg>
            ) : (
                <svg className="logo" width="133" height="38" viewBox="0 0 133 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="logo__rect" d="M32.5106 5.26154C32.5106 3.32429 31 2.5 29.0274 1.75385C29.0274 1.75385 20.9878 0 16 0C11.0122 0 3.48328 1.75385 3.48328 1.75385C1 2.5 0 3.32429 0 5.26154V25.7231C0 27.6603 1.55952 29.2308 3.48328 29.2308H29.0274C30.9511 29.2308 32.5106 27.6603 32.5106 25.7231V5.26154Z" fill="#4E4D82" />
                    <path d="M17.3368 37.2135C16.952 38.205 15.5587 38.205 15.1739 37.2135L5.81338 13.0948C5.51878 12.3358 5.9904 12.0066 6.86809 11.515C6.88574 11.5051 6.90083 11.4961 6.91793 11.4853C7.30602 11.2399 12.5005 8 16.2553 8C20.0159 8 25.2204 11.2497 25.5945 11.4864C25.6101 11.4963 25.6241 11.5047 25.6402 11.5138C26.5091 12.0061 26.9921 12.3352 26.6973 13.0948L17.3368 37.2135Z" fill="#FFBE00" />
                    <path d="M17.3368 37.2135C16.952 38.205 15.5587 38.205 15.1739 37.2135L5.81338 13.0948C5.51878 12.3358 5.9904 12.0066 6.86809 11.515C6.88574 11.5051 6.90083 11.4961 6.91793 11.4853C7.30602 11.2399 12.5005 8 16.2553 8C20.0159 8 25.2204 11.2497 25.5945 11.4864C25.6101 11.4963 25.6241 11.5047 25.6402 11.5138C26.5091 12.0061 26.9921 12.3352 26.6973 13.0948L17.3368 37.2135Z" fill="url(#paint0_linear_1103_813)" fillOpacity="0.2" />
                    <path id="logo__d" d="M121.72 23.26C120.733 23.26 119.833 23.0333 119.02 22.58C118.22 22.1133 117.58 21.4667 117.1 20.64C116.62 19.8 116.38 18.8 116.38 17.64C116.38 16.4933 116.62 15.5067 117.1 14.68C117.58 13.84 118.22 13.1933 119.02 12.74C119.833 12.2867 120.733 12.06 121.72 12.06C122.653 12.06 123.44 12.26 124.08 12.66C124.733 13.06 125.227 13.6733 125.56 14.5C125.893 15.3267 126.06 16.3733 126.06 17.64C126.06 18.9333 125.9 19.9933 125.58 20.82C125.26 21.6467 124.78 22.26 124.14 22.66C123.5 23.06 122.693 23.26 121.72 23.26ZM122.52 20.24C122.947 20.24 123.327 20.14 123.66 19.94C124.007 19.74 124.28 19.4467 124.48 19.06C124.68 18.66 124.78 18.1867 124.78 17.64C124.78 17.0933 124.68 16.6333 124.48 16.26C124.28 15.8733 124.007 15.58 123.66 15.38C123.327 15.18 122.947 15.08 122.52 15.08C122.08 15.08 121.687 15.18 121.34 15.38C121.007 15.58 120.74 15.8733 120.54 16.26C120.34 16.6333 120.24 17.0933 120.24 17.64C120.24 18.1867 120.34 18.66 120.54 19.06C120.74 19.4467 121.007 19.74 121.34 19.94C121.687 20.14 122.08 20.24 122.52 20.24ZM125.9 23.08C125.348 23.08 124.9 22.6323 124.9 22.08V21.22L124.92 17.64L124.72 14.06V9.24C124.72 8.68772 125.168 8.24001 125.72 8.24001H127.52C128.072 8.24001 128.52 8.68772 128.52 9.24001V22.08C128.52 22.6323 128.072 23.08 127.52 23.08H125.9Z" fill="#4E4D82" />
                    <path id="logo__r" d="M110 23.08C109.448 23.08 109 22.6323 109 22.08V13.24C109 12.6877 109.448 12.24 110 12.24H111.935C112.313 12.24 112.62 12.5469 112.62 12.9254V14.4764C112.62 14.7359 112.278 14.8305 112.145 14.608V14.608C112.104 14.5399 112.097 14.4564 112.128 14.3834C112.451 13.6284 112.955 13.0606 113.64 12.68C114.115 12.4076 114.656 12.2249 115.264 12.132C115.809 12.0486 116.26 12.5077 116.26 13.06V15.0178C116.26 15.2608 116.043 15.44 115.8 15.44V15.44C115.68 15.4267 115.547 15.42 115.4 15.42C114.627 15.42 114 15.6333 113.52 16.06C113.04 16.4733 112.8 17.1533 112.8 18.1V22.08C112.8 22.6323 112.352 23.08 111.8 23.08H110Z" fill="#4E4D82" />
                    <path id="logo__i2" d="M105 23.08C104.448 23.08 104 22.6323 104 22.08V13.24C104 12.6877 104.448 12.24 105 12.24H106.8C107.352 12.24 107.8 12.6877 107.8 13.24V22.08C107.8 22.6323 107.352 23.08 106.8 23.08H105ZM105.9 11.04C105.207 11.04 104.647 10.8467 104.22 10.46C103.793 10.0733 103.58 9.59333 103.58 9.02C103.58 8.44667 103.793 7.96667 104.22 7.58C104.647 7.19333 105.207 7 105.9 7C106.593 7 107.153 7.18667 107.58 7.56C108.007 7.92 108.22 8.38667 108.22 8.96C108.22 9.56 108.007 10.06 107.58 10.46C107.167 10.8467 106.607 11.04 105.9 11.04Z" fill="#4E4D82" />
                    <path id="logo__b2" d="M97.82 23.26C96.8467 23.26 96.0333 23.06 95.38 22.66C94.74 22.26 94.26 21.6467 93.94 20.82C93.62 19.9933 93.46 18.9333 93.46 17.64C93.46 16.3733 93.6267 15.3267 93.96 14.5C94.3067 13.6733 94.8067 13.06 95.46 12.66C96.1133 12.26 96.9 12.06 97.82 12.06C98.8067 12.06 99.7 12.2867 100.5 12.74C101.313 13.1933 101.953 13.84 102.42 14.68C102.9 15.5067 103.14 16.4933 103.14 17.64C103.14 18.8 102.9 19.8 102.42 20.64C101.953 21.4667 101.313 22.1133 100.5 22.58C99.7 23.0333 98.8067 23.26 97.82 23.26ZM92 23.08C91.4477 23.08 91 22.6323 91 22.08V9.24001C91 8.68772 91.4477 8.24001 92 8.24001H93.8C94.3523 8.24001 94.8 8.68772 94.8 9.24001V14.06L94.6 17.64L94.62 21.22V22.08C94.62 22.6323 94.1723 23.08 93.62 23.08H92ZM97.02 20.24C97.4467 20.24 97.8267 20.14 98.16 19.94C98.5067 19.74 98.78 19.4467 98.98 19.06C99.1933 18.66 99.3 18.1867 99.3 17.64C99.3 17.0933 99.1933 16.6333 98.98 16.26C98.78 15.8733 98.5067 15.58 98.16 15.38C97.8267 15.18 97.4467 15.08 97.02 15.08C96.5933 15.08 96.2067 15.18 95.86 15.38C95.5267 15.58 95.2533 15.8733 95.04 16.26C94.84 16.6333 94.74 17.0933 94.74 17.64C94.74 18.1867 94.84 18.66 95.04 19.06C95.2533 19.4467 95.5267 19.74 95.86 19.94C96.2067 20.14 96.5933 20.24 97.02 20.24Z" fill="#4E4D82" />
                    <path id="logo__t" d="M86.84 23.26C85.4533 23.26 84.3733 22.92 83.6 22.24C82.8267 21.5467 82.44 20.5067 82.44 19.12V10.82C82.44 10.2677 82.8877 9.82001 83.44 9.82001H85.24C85.7923 9.82001 86.24 10.2677 86.24 10.82V19.08C86.24 19.4667 86.3467 19.7733 86.56 20C86.7733 20.2133 87.0467 20.32 87.38 20.32V20.32C87.9962 20.32 88.7441 20.5575 88.9494 21.1385L89.2207 21.9064C89.3732 22.3378 89.2144 22.8304 88.78 22.9744C88.6297 23.0242 88.4697 23.0661 88.3 23.1C87.8333 23.2067 87.3467 23.26 86.84 23.26ZM81.86 15.48C81.3077 15.48 80.86 15.0323 80.86 14.48V13.64C80.86 13.0877 81.3077 12.64 81.86 12.64H87.72C88.2723 12.64 88.72 13.0877 88.72 13.64V14.48C88.72 15.0323 88.2723 15.48 87.72 15.48H81.86Z" fill="#4E4D82" />
                    <path id="logo__i1" d="M77 23.08C76.4477 23.08 76 22.6323 76 22.08V13.24C76 12.6877 76.4477 12.24 77 12.24H78.8C79.3523 12.24 79.8 12.6877 79.8 13.24V22.08C79.8 22.6323 79.3523 23.08 78.8 23.08H77ZM77.9 11.04C77.2067 11.04 76.6467 10.8467 76.22 10.46C75.7933 10.0733 75.58 9.59333 75.58 9.02C75.58 8.44667 75.7933 7.96667 76.22 7.58C76.6467 7.19333 77.2067 7 77.9 7C78.5933 7 79.1533 7.18667 79.58 7.56C80.0067 7.92 80.22 8.38667 80.22 8.96C80.22 9.56 80.0067 10.06 79.58 10.46C79.1667 10.8467 78.6067 11.04 77.9 11.04Z" fill="#4E4D82" />
                    <path id="logo__b1" d="M69.82 23.26C68.8467 23.26 68.0333 23.06 67.38 22.66C66.74 22.26 66.26 21.6467 65.94 20.82C65.62 19.9933 65.46 18.9333 65.46 17.64C65.46 16.3733 65.6267 15.3267 65.96 14.5C66.3067 13.6733 66.8067 13.06 67.46 12.66C68.1133 12.26 68.9 12.06 69.82 12.06C70.8067 12.06 71.7 12.2867 72.5 12.74C73.3133 13.1933 73.9533 13.84 74.42 14.68C74.9 15.5067 75.14 16.4933 75.14 17.64C75.14 18.8 74.9 19.8 74.42 20.64C73.9533 21.4667 73.3133 22.1133 72.5 22.58C71.7 23.0333 70.8067 23.26 69.82 23.26ZM64 23.08C63.4477 23.08 63 22.6323 63 22.08V9.24001C63 8.68772 63.4477 8.24001 64 8.24001H65.8C66.3523 8.24001 66.8 8.68772 66.8 9.24001V14.06L66.6 17.64L66.62 21.22V22.08C66.62 22.6323 66.1723 23.08 65.62 23.08H64ZM69.02 20.24C69.4467 20.24 69.8267 20.14 70.16 19.94C70.5067 19.74 70.78 19.4467 70.98 19.06C71.1933 18.66 71.3 18.1867 71.3 17.64C71.3 17.0933 71.1933 16.6333 70.98 16.26C70.78 15.8733 70.5067 15.58 70.16 15.38C69.8267 15.18 69.4467 15.08 69.02 15.08C68.5933 15.08 68.2067 15.18 67.86 15.38C67.5267 15.58 67.2533 15.8733 67.04 16.26C66.84 16.6333 66.74 17.0933 66.74 17.64C66.74 18.1867 66.84 18.66 67.04 19.06C67.2533 19.4467 67.5267 19.74 67.86 19.94C68.2067 20.14 68.5933 20.24 69.02 20.24Z" fill="#4E4D82" />
                    <path id="logo__a" d="M59.62 23.08C59.0677 23.08 58.62 22.6323 58.62 22.08V21.3045C58.62 21.1438 58.5813 20.9856 58.5072 20.8431L58.4728 20.7769C58.3987 20.6344 58.36 20.4761 58.36 20.3155V16.84C58.36 16.24 58.1733 15.78 57.8 15.46C57.44 15.1267 56.86 14.96 56.06 14.96C55.54 14.96 55.0133 15.0467 54.48 15.22C54.3825 15.2492 54.2878 15.2807 54.1956 15.3144C53.5999 15.5323 52.8553 15.3665 52.5734 14.7983L52.2351 14.1164C52.0098 13.6622 52.1579 13.1044 52.6182 12.8919C53.0479 12.6935 53.5218 12.5295 54.04 12.4C54.9067 12.1733 55.7667 12.06 56.62 12.06C58.38 12.06 59.74 12.4667 60.7 13.28C61.6733 14.08 62.16 15.34 62.16 17.06V22.08C62.16 22.6323 61.7123 23.08 61.16 23.08H59.62ZM55.42 23.26C54.5533 23.26 53.82 23.1133 53.22 22.82C52.62 22.5267 52.16 22.1267 51.84 21.62C51.5333 21.1133 51.38 20.5467 51.38 19.92C51.38 19.2533 51.5467 18.68 51.88 18.2C52.2267 17.7067 52.7533 17.3333 53.46 17.08C54.1667 16.8133 55.08 16.68 56.2 16.68H57.77C58.3168 16.68 58.76 17.1232 58.76 17.67V17.67C58.76 18.2168 58.3168 18.66 57.77 18.66H56.72C56.1067 18.66 55.6733 18.76 55.42 18.96C55.18 19.16 55.06 19.4267 55.06 19.76C55.06 20.0933 55.1867 20.36 55.44 20.56C55.6933 20.76 56.04 20.86 56.48 20.86C56.8933 20.86 57.2667 20.76 57.6 20.56V20.56C57.9671 20.3341 58.6444 20.3657 58.7945 20.7698L58.8247 20.8511C58.8607 20.948 58.8666 21.054 58.8357 21.1526C58.6246 21.8243 58.246 22.3401 57.7 22.7C57.1267 23.0733 56.3667 23.26 55.42 23.26Z" fill="#4E4D82" />
                    <path id="logo__h" d="M46.06 12.06C46.9 12.06 47.66 12.2333 48.34 12.58C49.02 12.9133 49.5533 13.4333 49.94 14.14C50.34 14.8467 50.54 15.76 50.54 16.88V22.08C50.54 22.6323 50.0923 23.08 49.54 23.08H47.74C47.1877 23.08 46.74 22.6323 46.74 22.08V17.5C46.74 16.7267 46.58 16.1667 46.26 15.82C45.9533 15.46 45.52 15.28 44.96 15.28C44.56 15.28 44.1933 15.3733 43.86 15.56C43.5267 15.7333 43.2667 16.0067 43.08 16.38C42.8933 16.7533 42.8 17.24 42.8 17.84V22.08C42.8 22.6323 42.3523 23.08 41.8 23.08H40C39.4477 23.08 39 22.6323 39 22.08V9.24001C39 8.68772 39.4477 8.24001 40 8.24001H41.8C42.3523 8.24001 42.8 8.68772 42.8 9.24001V14.3492C42.8 14.7039 42.3693 14.8795 42.1213 14.6259V14.6259C42.0013 14.5031 41.9727 14.3161 42.0612 14.169C42.4591 13.5074 42.9788 13.0044 43.62 12.66C44.34 12.26 45.1533 12.06 46.06 12.06Z" fill="#4E4D82" /></svg>
            )}


            <svg width="33"  className="logo logo_mobile" height="38" viewBox="0 0 33 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M32.5106 5.26154C32.5106 3.32429 31 2.5 29.0274 1.75385C29.0274 1.75385 20.9878 0 16 0C11.0122 0 3.48328 1.75385 3.48328 1.75385C1 2.5 0 3.32429 0 5.26154V25.7231C0 27.6603 1.55952 29.2308 3.48328 29.2308H29.0274C30.9511 29.2308 32.5106 27.6603 32.5106 25.7231V5.26154Z" fill="#4E4D82"/>
                <path d="M17.3368 37.2135C16.952 38.205 15.5587 38.205 15.1739 37.2135L5.81338 13.0948C5.51878 12.3358 5.9904 12.0066 6.86809 11.515C6.88574 11.5051 6.90083 11.4961 6.91793 11.4853C7.30602 11.2399 12.5005 8 16.2553 8C20.0159 8 25.2204 11.2497 25.5945 11.4864C25.6101 11.4963 25.6241 11.5047 25.6402 11.5138C26.5091 12.0061 26.9921 12.3352 26.6973 13.0948L17.3368 37.2135Z" fill="#FFBE00"/>
                <path d="M17.3368 37.2135C16.952 38.205 15.5587 38.205 15.1739 37.2135L5.81338 13.0948C5.51878 12.3358 5.9904 12.0066 6.86809 11.515C6.88574 11.5051 6.90083 11.4961 6.91793 11.4853C7.30602 11.2399 12.5005 8 16.2553 8C20.0159 8 25.2204 11.2497 25.5945 11.4864C25.6101 11.4963 25.6241 11.5047 25.6402 11.5138C26.5091 12.0061 26.9921 12.3352 26.6973 13.0948L17.3368 37.2135Z" fill="url(#paint0_linear_1201_441)" fillOpacity="0.2"/>
                <defs>
                <linearGradient id="paint0_linear_1201_441" x1="16.2548" y1="8" x2="16.2548" y2="37.9571" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFA800"/>
                <stop offset="1" stopColor="#FF3D00"/>
                </linearGradient>
                </defs>
            </svg>

            <defs>
                <linearGradient id="paint0_linear_1103_813" x1="16.2548" y1="8" x2="16.2548" y2="37.9571" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFA800" />
                    <stop offset="1" stopColor="#FF3D00" />
                </linearGradient>
            </defs>
        </div>

    )
}

export default Logo;