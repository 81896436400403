import React, { useState, useEffect, ReactEventHandler } from "react";
import { Link } from "react-router-dom";

function RoutineItem(props: any) {
    const { routine, index, deleteCallback, renameCallback } = props;

    return (
        <div className="routine__item">
            <div className="routine__icon">{routine.icon}</div>
            <Link to={`/routines/${routine.id}`} className="routine__name" >
                <span>{routine.name}</span></Link>
            
            {/* <div className="routine__descr">12 hours this year</div> */}
            <button className="btn btn_gray-outline" onClick={renameCallback} >Rename</button>
            <button className="btn btn_transparent routines__close" onClick={deleteCallback}>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.0625 5.625H3.9375V15.1875C3.9375 15.8091 4.44094 16.3125 5.0625 16.3125H12.9375C13.5591 16.3125 14.0625 15.8091 14.0625 15.1875V5.625Z" stroke="#CB6217" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11.25 3.9375H6.75V2.8125C6.75 2.19094 7.25344 1.6875 7.875 1.6875H10.125C10.7466 1.6875 11.25 2.19094 11.25 2.8125V3.9375Z" stroke="#CB6217" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M15.75 5.625H2.25V4.5C2.25 4.1895 2.502 3.9375 2.8125 3.9375H15.1875C15.498 3.9375 15.75 4.1895 15.75 4.5V5.625Z" stroke="#CB6217" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7.3125 9L10.6875 12.375" stroke="#CB6217" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7.3125 12.375L10.6875 9" stroke="#CB6217" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </button>
        </div>
    );
}

export default RoutineItem;