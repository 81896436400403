//import { Link } from "react-router-dom";

import { useLayoutEffect } from "react";

function Footer(props: any) {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8">
            {props.user ? (
              <ul className="footer-menu">
                <li><a href="/premium">Buy premium</a></li>
                <li><a href="/tasks">Tasks</a></li>
                <li><a href="/routines">Routines</a></li>
                <li><a href="/settings">Settings</a></li>
              </ul>
            ) : (
              <ul className="footer-menu">
                <li><a href="/">Home</a></li>
                <li><a href="/i/tasks">Tasks</a></li>
                <li><a href="/i/routines">Routines</a></li>
                <li><a href="/i/pricing">Pricing</a></li>
                <li><a href="/login">Sign in</a></li>
                <li><a href="/signup">Sign up</a></li>
              </ul>
            )}
          </div>
          <div className="col-12 col-md-4">social buttons</div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
