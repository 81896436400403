import React, { useState, useEffect, ReactEventHandler } from "react";
import { IActivity, ITask, IUser } from "../interfaces/interfaces";
import authHeader from "../services/auth-header";
import axios from "axios";
import ActivityLine from "../components/ActivityLine";
import AuthService from "../services/auth.service";
import { fetchUserData } from "../services/q";
import { fetchActivitiesInitial, fetchFullCalendar } from "../services/q";
import categories from "../assets/categories.json";
import Heatmap from "../components/Heatmap/Heatmap";

import "react-calendar-heatmap/dist/styles.css";
import { act } from "react-dom/test-utils";

const API_URL = "https://krgb.velichko.co";

const ActivityCalendar = (props: any) => {
  let [is_error, setError] = useState(false);
  let [error_message, setErrorMessage] = useState("");
  let [is_success, setSuccess] = useState(false);
  let [is_full_loaded, setFullLoaded] = useState(false);
  let [success_message, setSuccessMessage] = useState("");
  let [activitiesM, setActivitiesM] = useState<Array<IActivity>>([]);
  let [activitiesY, setActivitiesY] = useState<Array<any>>([]);
  let [catigorizedActivities, setCatigorizedActivities] = useState<Array<any>>(
    []
  );
  let [activeCategories, setActiveCategories] = useState(0);
  let [mode, setMode] = useState(false);

  const today = new Date();
  const start_date = new Date(today.getTime() - 365 * 24 * 60 * 60 * 1000);

  useEffect(() => {
    document.title = "Activity Calendar | Kerge.lt";
    initialLoad();
  }, []);

  const initialLoad = async () => {
    fetchActivitiesInitial()
      .then((result) => {
        setCatigorizedActivities(cactegorizeActivitiesbyCategoryId(result));
        setActivitiesM(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cactegorizeActivitiesbyCategoryId = (activities: Array<IActivity>) => {
    let active_categories = 0;
    let result = categories.map((category, i) => {
      let activitiesByCategory = activities.filter(
        (activity) => activity.categoryId === i
      );
      if (activitiesByCategory.length > 0) {
        active_categories++;
        return activitiesByCategory;
      }
    });
    setActiveCategories(active_categories);
    console.log(result);
    return result;
  };

  // const switchMode = () => {
  //   setMode(!mode);
  //   if (!is_full_loaded) {
  //     let n = fetchFullCalendar().then((result) => {
  //       let result2 = result.filter((item: any) => {
  //         return JSON.parse(item.content).length > 0
  //       })
  //       for (let res of result2) {
  //         console.log(res)
  //         res.count = JSON.parse(res.content).length
  //       }
  //       console.log(result2)
  //       setActivitiesY(result2)
  //     });
  //     console.log(n)
  //     setFullLoaded(true)
  //   }
  // }

  const switchToYearMode = () => {
    setMode(true);
    if (!is_full_loaded) {
      let n = fetchFullCalendar().then((result) => {
        let result2 = result.filter((item: any) => {
          return JSON.parse(item.content).length > 0;
        });
        for (let res of result2) {
          res.count = JSON.parse(res.content).length;
        }
        setActivitiesY(result2);
      });
      setFullLoaded(true);
    }
  };

  //

  // get all the tasks for the period
  // get min time and max time

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="activity-calender__header">
            <h1>Activity calendar</h1>
            <div className="submenu activity-calender__menu">
              <div
                className={
                  !mode
                    ? "submenu-item activity-calender__menu-item active"
                    : "submenu-item activity-calender__menu-item"
                }
                onClick={() => setMode(false)}
              >
                Month
              </div>
              <div
                className={
                  mode
                    ? "submenu-item activity-calender__menu-item active"
                    : "submenu-item activity-calender__menu-item"
                }
                onClick={switchToYearMode}
              >
                Year
              </div>
            </div>
          </div>

          <div className="alert alert-danger" role="alert" hidden={!is_error}>
            {error_message}
          </div>
          <div
            className="alert alert-success"
            role="alert"
            hidden={!is_success}
          >
            {success_message}
          </div>
          <div className="block activities__block activities">
            {mode ? (
              <div>
                <Heatmap activities={activitiesY} />
              </div>
            ) : (
              <div>
                {activitiesM.length ? (
                  activeCategories > 1 ? (
                    catigorizedActivities.map((arr, index) =>
                      arr ? (
                        <div className="activities__category">
                          
                          <h5 className="activities__category-name">{categories[index]}</h5>
                          {arr.map((activity: any, index: any) => (
                            <ActivityLine
                              key={activity.id}
                              activity={activity}
                            />
                          ))}
                        </div>
                      ) : (
                        ""
                      )
                    )
                  ) : (
                    activitiesM.map((activity, index) => (
                      <ActivityLine key={activity.id} activity={activity} />
                    ))
                  )
                ) : (
                  <div className="no-tasks">
                    👎🏿👎🏿👎🏿 You have no routines, add them first PLZ ty
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityCalendar;
