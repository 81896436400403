export const formatToYMD = (date: Date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
};

export const formatToString = (date: Date) => {
    let text_date = date.toLocaleDateString('en-US', { day: 'numeric', month: 'long' });
    let day_of_week = date.toLocaleDateString('en-US', { weekday: 'long' });
    return [day_of_week, text_date]
};

export const formatToShortString = (str_date: any) => {
    let date = new Date(str_date);
    return date.toLocaleDateString('en-US', { day: 'numeric', month: 'short' });
};

export const addDay = (date: Date) => {
    date.setDate(date.getDate() + 1);
    return date;
};

export const deductDay = (date: Date) => {
    date.setDate(date.getDate() - 1);
    return date;
};
