import React, { useState, useEffect, useCallback, ReactEventHandler } from "react";
import { Route, Link, Routes, useParams } from 'react-router-dom';
import { IRoutine } from "../interfaces/interfaces";
import { fetchRoutine, setRoutineCategory, setRoutineTime } from "../services/q";
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { removeUserData, setUserData, selectUserData } from '../stores/userData/userSlice';
import { debounce } from "lodash"

const RoutinePage = () => {

  const params = useParams();
  const userData = useAppSelector(selectUserData);
  let [is_error, setError] = useState(false);
  let [routine, setRoutine] = useState<IRoutine>({} as IRoutine);
  let [task_times, setTaskTimes] = useState<any>({});

  const categories = [
    { "id": 0, "name": "General", "hardcoded": true },
    { "id": 1, "name": "Languages", "hardcoded": true },
    { "id": 2, "name": "Activities", "hardcoded": true },
    { "id": 3, "name": "Crafts and art", "hardcoded": true },
    { "id": 4, "name": "Business and marketing", "hardcoded": true },
    { "id": 5, "name": "Humanities", "hardcoded": true },
    { "id": 6, "name": "Natural Sciences", "hardcoded": true },
    { "id": 7, "name": "Engineering and IT", "hardcoded": true }
  ]
 
  useEffect(() => {
    document.title = 'Routine | Kerge.lt';
    fetchRoutine(params.id)
      .then((routine: any) => {
        setRoutine(routine);
        const today = new Date();
        const week_ago = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
        const month_ago = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
        const year_time = routine.tasks.reduce((acc: number, task: any) => acc + task.time, 0);
        const week_time = routine.tasks.reduce((acc: number, task: any) => {
          const task_date = new Date(task.date);
          if (task_date > week_ago) return acc + task.time;
          return acc;
        }, 0);
        const month_time = routine.tasks.reduce((acc: number, task: any) => {
          const task_date = new Date(task.date);
          if (task_date > month_ago) return acc + task.time;
          return acc;
        }, 0);
        setTaskTimes({ year: year_time, month: month_time, week: week_time });
      })
      .catch(() => {
        setError(true);
      });
  }, []);

  const changeRoutineTime = (time: number) => {
    setRoutine({ ...routine, time: time });
    debounceChangeRoutineTime(routine, time);
  }

  const debounceChangeRoutineTime = useCallback(debounce((routine, time) => {
    console.log("debounceChangeRoutineTime");
    if (routine.id) setRoutineTime(routine.id, time)
  }, 500), []);

  const changeRoutineCategory = (id: number) => {
    setRoutine({ ...routine, categoryId: id });
    if (routine.id) setRoutineCategory(routine.id, id)
  }

  const getTaskTimeText = (time: number) => {
    if (time < 60) return `${time} minutes`;
    else return `${Math.floor(time / 60)} hours ${time % 60} minutes`;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="page-header">
            <h1>{routine.icon} {routine.name}</h1>
          </div>
          {is_error ? (
            <div className="alert alert-danger tasks__alert" role="alert">
              🚩 We're sorry, something went wrong
            </div>
          ) : (
            ""
          )}
          <div className="block block_nopadding routines__block routines">
            {(task_times.year > 0 &&
            <div className="routine__times">
              <div className="routine__time">
                <h5>{getTaskTimeText(task_times.year)}</h5> this year</div>
              <div className="routine__time"><h5>{getTaskTimeText(task_times.month)}</h5> this month</div>
              <div className="routine__time"><h5>{getTaskTimeText(task_times.week)}</h5> this week</div>
            </div>
            )}

            <form className="routine__routine-form">
              <div className="form-group">
                <label className="center-label" htmlFor="settingsCategory">Category</label>
                <select className="form-select"  value={routine.categoryId} onChange={(e: any) => changeRoutineCategory(e.target.value)} >
                  {categories.map((category, index) => (
                    <option key={index} value={category.id}>{category.name}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label className="center-label" htmlFor="settingsPasswordInput">Average time (in minutes)</label>
                <input name="time" type="number" onChange={(e: any) => changeRoutineTime(e.target.value)} className="form-control" value={routine.time} id="settingsRoutineInput" placeholder="Time"></input>
              </div>
            </form>
          </div>

        </div>
      </div>

    </div>
  );
}

export default RoutinePage;