import { Link } from "react-router-dom";

// redux
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { removeUserData, setUserData, selectUserData } from '../../stores/userData/userSlice';
import AuthService from "../../services/auth.service";
import logo from '../../assets/images/logo.svg';
import Logo from '../../components/Logo';

function LoggedIn(props: any) {

    const userData = useAppSelector(selectUserData);
    const dispatch = useAppDispatch();

    const logOut = () => {
        dispatch(removeUserData());
        AuthService.logout();
        window.location.replace("/")
    };

    return (
        <div className="row nav-row">
            <div className="col-3 nav__col-logo">
                <Link to={"/tasks"} className="navbar-brand">
                    {/* <img src={logo} /> */}
                    <Logo theme={userData.settings_use_dark_theme}/>
                
                </Link>
            </div>
            <div className="col-9 nav__col-right" >
                <Link to={"/tasks"} className="nav-link">
                    Tasks
                </Link>
                <Link to={"/routines"} className="nav-link">
                    Routines
                </Link>
                <Link to={"/activity"} className="nav-link">
                    Activity
                </Link>
                <Link to={"/settings"} className="nav-link">
                    Settings
                </Link>
            </div>
        </div>
    );
}

export default LoggedIn;