import { Link } from "react-router-dom";

// redux
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { removeUserData, setUserData, selectUserData } from '../../stores/userData/userSlice';
import AuthService from "../../services/auth.service";

import Logo from '../../components/Logo';

function Unlogged(props: any) {
    const dispatch = useAppDispatch();

    const logOut = () => {
        dispatch(removeUserData());
        AuthService.logout();
    };

    return (
        <div className="row nav-row">
            <div className="col-3 nav__col-logo">
                <Link to={"/"} className="navbar-brand">
                    <Logo />
                </Link>
            </div>
            {/* <div className="col-4 nav__col-center" id="basic-navbar-nav">
                { <Link to={"/l/routines"} className="nav-link">
                    Routines
                </Link>
                <Link to={"/l/calendar"} className="nav-link">
                    Calendar
                </Link>
                <Link to={"/l/pricing"} className="nav-link">
                    Pricing
                </Link> }
            </div> */}
            <div className="col-9 nav__col-right" >
                <Link to={"/login"} className="nav-link">
                    Log in
                </Link>
                <Link to={"/signup"} className="btn btn_green nav__btn">
                    Sign up
                </Link>

            </div>
        </div>

    );
}

export default Unlogged;