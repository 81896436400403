// main
import React from 'react';
import { useState, useEffect } from "react";
import { Routes, BrowserRouter as Router, Route, Link } from "react-router-dom";
import AuthService from "./services/auth.service";

// pages
import Login from "./pages/Login";
import Home from "./pages/Home";
import Signup from './pages/Signup';
import Routines from './pages/Routines';
import Tasks from './pages/Tasks';
import ActivityCalendar from './pages/ActivityCalendar';
import Routine from './pages/Routine';
import OauthGithub from './pages/Redirects/Github';
import OauthGoogle from './pages/Redirects/Google';

// components
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";

// assets
import './assets/styles/main.scss';

// redux
import { useAppSelector, useAppDispatch } from './app/hooks';
import { removeUserData, setUserData, selectUserData } from './stores/userData/userSlice';
import Settings from './pages/Settings';

function App() {
  //const userData = useAppSelector(selectUserData);
  const dispatch = useAppDispatch();

  let [user, setUser] = useState(AuthService.getCurrentUser());
  const userData = useAppSelector(selectUserData);

  useEffect(() => {
    if (user) {
      dispatch(setUserData(user));
    }
  }, []);

  return (
    <div className={userData.settings_use_dark_theme ? "App night" : "App"}>
      <Router>
        {/* <div className="beta-warning">
          <div className="container">
            <div className="row">
              <div className="col-12"> This app works in beta mode. You probably should not even be here. Go away.</div>
            </div>
          </div>
        </div> */}
        <NavigationBar user={user}/>
        <div className="page">
          <Routes>
            <Route path="/" element={<Home user={user}/>} />
            <Route path="/routines" element={<Routines/>} />
            <Route path="/tasks" element={<Tasks />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/settings" element={<Settings/>} />
            <Route path="/activity" element={<ActivityCalendar/>} />
            <Route path="/routines/:id" element={<Routine/>} />
            <Route path="/redirects/github" element={<OauthGithub/>} />
            <Route path="/redirects/google" element={<OauthGoogle/>} />
            <Route path='*' element={<div>404</div>} />
          </Routes>
        </div>
      
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
