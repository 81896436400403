import React, { useState, useEffect, ReactEventHandler } from "react";
import { formatToShortString } from "../../services/dates";
// import {formatToShortString} from "../../services/dates";

function Heatmap(props: any) {
    let dates = []

    const { activities } = props;
    console.log(activities)

    let sessions = [];
    let now = new Date();
    let today = new Date(now.toDateString())
    let today_int = today.getTime();
    const start_date = new Date(today.getTime() - 365 * 24 * 60 * 60 * 1000);

    const userTimezoneOffset = now.getTimezoneOffset() * 60000;

    let is_started = false;
    for (let i = 1; i <= 365; i++) {
        const date = new Date(today_int - (365 - i) * 24 * 60 * 60 * 1000);
        if (date.getDay() == 0) is_started = true;
        if (is_started) {
            let data = activities.filter((item: any) => {
                return new Date(item.date).getTime() == (date.getTime() - userTimezoneOffset)
            })
            let text = "";
            let count = 0;
            if (data.length > 0) {
                let content = JSON.parse(data[0].content)
                text = content.reduce((prev: string, cur: any, index: number) => {
                    console.log(index)
                    prev += `${cur.text}: ${cur.time} min`;
                    prev += index == content.length - 1 ? "" : ", ";
                    count++;
                    return prev
                }, "")
            }
            dates.push({ date: date.toDateString(), data: text, count: count })
        }
    }

    let dates_by_week = [];
    let week = [];
    let months = new Set();
    const month_names = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // group by weeks
    for (let i = 0; i < dates.length; i++) {

        if ((i % 7 == 0) && (i != 0)) {
            dates_by_week.push(week);
            week = [];
            months.add(month_names[new Date(dates[i].date).getMonth()])
        }
        week.push(dates[i]);
        if (i == dates.length - 1) dates_by_week.push(week)
    }

    const months_array: Array<any> = Array.from(months);

    return (
        <div className="heatmap-container">
            <div className="heatmap__month-labels">
                {months_array.map((item: string, i: number) => (
                    <div key={i} className="heatmap__month-label">{item}</div>
                ))}
            </div>
            <div className="heatmap">
            <div className="heatmap__day-labels">
                <div className="heatmap__day-label">Sun</div>
                <div className="heatmap__day-label">Mon</div>
                <div className="heatmap__day-label">Tue</div>
                <div className="heatmap__day-label">Wed</div>
                <div className="heatmap__day-label">Thu</div>
                <div className="heatmap__day-label">Fri</div>
                <div className="heatmap__day-label">Sat</div>
            </div>
            {dates_by_week.map((date: any, i: number) => (
                <div className="heatmap__week" key={i}>
                    {date.map((item: any, j: number) =>
                        <div key={j} className={item.count ? "heatmap__date active" : "heatmap__date"}>
                            <div className={item.count ? "heatmap__date-info active" : "heatmap__date-info inactive"}>
                                <h5>{formatToShortString(item.date)}</h5>
                                {item.data.length > 0 && <div>{item.data}</div>}
                            </div>
                        </div>
                    )}
                </div>
            ))}

        </div>
        </div>
        
    );
}

export default Heatmap;