import React, { useState, useEffect, ReactEventHandler } from "react";
import { IRoutine, ITask, IUser } from "../interfaces/interfaces";
import authHeader from "../services/auth-header";
import axios from "axios";
import SettingsCategories from "../components/Settings/Categories";
import AuthService from "../services/auth.service";
import { fetchUserData } from "../services/q";

import {
  validateUserData, validateNewPassword
} from "../services/validators";

import { useAppSelector, useAppDispatch } from '../app/hooks';
import { removeUserData, setUserSettings, selectUserData, switchTheme } from '../stores/userData/userSlice';

const API_URL = "https://krgb.velichko.co";

const Settings = (props: any) => {
  const userData = useAppSelector(selectUserData);
  let [is_error, setError] = useState(false);
  let [error_message, setErrorMessage] = useState("");
  let [is_success, setSuccess] = useState(false);
  let [success_message, setSuccessMessage] = useState("");
  let [user, setUser] = useState(AuthService.getCurrentUser());
  let [categories, setCategories] = useState([] as Array<any>);
  let [new_password, setNewPassword] = useState("");
  let [new_password_confirm, setNewPasswordConfirm] = useState("");

  const dispatch = useAppDispatch();

  const logOut = () => {
    dispatch(removeUserData());
    AuthService.logout();
    window.location.replace("/")
  };

  const switchUserTheme = () => {
    dispatch(switchTheme());
    localStorage.setItem("user", JSON.stringify({ ...userData, settings_use_dark_theme: !userData.settings_use_dark_theme }))
  }

  useEffect(() => {
    document.title = "Settings | Kerge.lt";
    fetchUserData(user.id).then((data) => {
      const { userData, categories } = data;
      setUser(userData);
      setCategories(categories);
    }).catch((error) => {
      setError(true);
      setErrorMessage("Can't load user data");
    });
  }, []);

  const saveUserSettings = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    const data = user;
    if (validateUserData(data)) {
      let result = await axios
        .put(API_URL + "/user/settings", data, { headers: authHeader() })
        .then((response) => {
          let local_storage_user = AuthService.getCurrentUser();
          local_storage_user.name = data.name;
          local_storage_user.email = data.email;
          localStorage.setItem("user", JSON.stringify(local_storage_user));
          setSuccess(true);
          setSuccessMessage("Account information changed successfully");
          return response.data
        })
        .catch((err) => {
          setSuccess(false);
          setError(true);
          setErrorMessage(`${err.message}. Make sure this email is not already in use.`);
          throw new Error(err);
        });
      let user_to_store = result;
      user_to_store.token = user.token;
      dispatch(setUserSettings(result));
    }
    else {
      setError(true);
      setErrorMessage("Please check the form for errors");
    }
  };

  const setNewPasswordHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    if (validateNewPassword(new_password, new_password_confirm)) {
      let result = await axios
        .put(API_URL + "/user/settings/password", { password: new_password, id: user.id }, { headers: authHeader() })
        .then((response) => {
          setSuccess(true);
          setSuccessMessage("Password changed successfully");
          setNewPassword("");
          setNewPasswordConfirm("");
          return response.data
        }
        )
        .catch((err) => {
          setError(true);
          setErrorMessage(err.response.data.message);
          throw new Error(err);
        });
    }
    else {
      setError(true);
      setErrorMessage("Passwords do not match");
    }
  };


  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-8">
          <h1>Settings</h1>

          <div className="alert alert-danger" role="alert" hidden={!is_error}>
            {error_message}
          </div>
          <div className="alert alert-success" role="alert" hidden={!is_success}>
            {success_message}
          </div>



          <form className="block settings__block">
            <h5>Night theme</h5>
            <div className="form-group nomargin">
              <div onClick={switchUserTheme} className="form-check form-switch">
                <input checked={userData.settings_use_dark_theme} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Enable night theme</label>
              </div>
            </div>
          </form>

          <form className="block settings__block" onSubmit={(e) => { saveUserSettings(e); e.preventDefault() }}>
            <h5>Account</h5>
            {user.type === 'github' && <p className="settings__oauth-paragraph"> <svg className="mr-2 pr-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1006_418)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00001 15.3333C3.94992 15.3333 0.666672 12.0501 0.666672 8.00001C0.666672 3.94992 3.94992 0.666672 8.00001 0.666672C12.0501 0.666672 15.3333 3.94992 15.3333 8.00001C15.3333 12.0501 12.0501 15.3333 8.00001 15.3333ZM8.00001 14C11.3137 14 14 11.3137 14 8.00001C14 4.6863 11.3137 2.00001 8.00001 2.00001C4.6863 2.00001 2.00001 4.6863 2.00001 8.00001C2.00001 11.3137 4.6863 14 8.00001 14ZM6.66667 9.0572L10.1953 5.5286L11.1381 6.47141L6.66667 10.9428L4.19527 8.47141L5.13808 7.5286L6.66667 9.0572Z" fill="#62AB5C" />
              </g>
              <defs>
                <clipPath id="clip0_1006_418">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg> You are authorized with GitHub</p>}
            {user.type === 'google' && <p className="settings__oauth-paragraph">
              <img referrerPolicy="no-referrer" className="settings__oauth-avatar" src={user.avatar}/>
              You are authorized with Google
              </p>}
            <div className="form-group">
              <label htmlFor="settingsEmailInput">Email address</label>
              <input name="email" disabled={user.type === 'google'} type="email" className="form-control" defaultValue={user?.email} onChange={e => setUser({ ...user, email: e.target.value })} id="settingsEmailInput" placeholder="email"></input>
            </div>
            <div className="form-group">
              <label htmlFor="settingsNameInput">Your name</label>
              <input name="name" type="name" defaultValue={user?.name} onChange={e => setUser({ ...user, name: e.target.value })} className="form-control" id="settingsNameInput" placeholder="name"></input>
            </div>
            <input type="submit" className="btn btn-primary" value="Save"></input>
          </form>

          <form className="block settings__block" onSubmit={(e) => { setNewPasswordHandler(e); e.preventDefault() }}>
            <h5>Password</h5>
            <div className="form-group">
              <label htmlFor="settingsPasswordInput">New password</label>
              <input name="password" type="password" onChange={e => setNewPassword(e.target.value)} className="form-control" id="settingsPasswordInput" placeholder="***"></input>
            </div>
            <div className="form-group">
              <label htmlFor="settingsPasswordConfirmInput">Confirm password</label>
              <input name="password2" type="password" onChange={e => setNewPasswordConfirm(e.target.value)} className="form-control" id="settingsPasswordConfirmInput" placeholder="***"></input>
            </div>
            <input type="submit" className="btn btn-primary" value="Save new password"></input>
          </form>

          <div className="block settings__block">
            <h5>Log out</h5>
            <a className="btn btn_gray-outline settings__log-out" onClick={logOut}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.96 8H14.46" stroke="#777777" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.965 12.5V14H3.965V2H11.965V3.5H12.965V1.5C12.965 1.36739 12.9123 1.24021 12.8186 1.14645C12.7248 1.05268 12.5976 1 12.465 1H3.465C3.33239 1 3.20521 1.05268 3.11145 1.14645C3.01768 1.24021 2.965 1.36739 2.965 1.5V14.5C2.965 14.6326 3.01768 14.7598 3.11145 14.8536C3.20521 14.9473 3.33239 15 3.465 15H12.465C12.5976 15 12.7248 14.9473 12.8186 14.8536C12.9123 14.7598 12.965 14.6326 12.965 14.5V12.5H11.965Z" fill="#777777" />
                <path d="M14.46 8L12.46 10" stroke="#777777" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M14.46 8L12.46 6" stroke="#777777" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12.46 4.045V3.045" stroke="#777777" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12.46 13V12" stroke="#777777" stroke-linecap="round" stroke-linejoin="round" />
              </svg> Log out
            </a>
          </div>



        </div>
      </div>
    </div>
  );
};

export default Settings;
