import axios from "axios";
import { access } from "fs";
const API_URL = "https://krgb.velichko.co";

const signup = (email: string, name: string, password: string) => {
  return axios
    .post(API_URL + "/user/signup", {
      email,
      password,
      name,
    })
    .then((response) => {
      if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const login = (email: string, password: string) => {
  return axios
    .post(API_URL + "/user/login", {
      email,
      password,
    })
    .then((response) => {
      if (response.data) {
        console.log(response.data)
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
  window.location.replace("/");
  window.location.reload();
};

const getCurrentUser = () => {
  let user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

const githubLogin = async (code: string) => {
  const result = await axios
    .post(API_URL + "/user/login/github", {
      code,
    })
    .then(async (response) => {
      console.log(response.data)
      const {access_token} = response.data;
      const github_data = await axios({
        method: "get",
        url: `https://api.github.com/user`,
        headers: {
          Authorization: "token " + access_token
        },
      }).then(async (response) => {
        console.log("https://api.github.com/user", response)
        const success_login = await axios
          .post(API_URL + "/user/login/github/success", response.data)
          .then((response) => {
            console.log("API_URL/user/login/github/success")
            console.log(response.data)
            localStorage.setItem("user", JSON.stringify(response.data));
            return response.data
          })
          .catch((err) => {
            throw err;
          });
          return success_login
      });
      return github_data
    })
    .catch((err) => {
      console.log("API_URL/user/login/github")
      console.log(err);
      //throw err;
    });
    return result
};

const googleLogin = async (code: string) => {
  const result = await axios
    .post(API_URL + "/user/login/google", {
      code,
    })
    .then(async (response) => {
      localStorage.setItem("user", JSON.stringify(response.data));
      return response.data
    })
    .catch((err) => {
      console.log("API_URL/user/login/google")
      console.log(err);
      //throw err;
    });
    return result
};

const authService = {
  signup,
  login,
  logout,
  getCurrentUser,
  githubLogin,
  googleLogin
};

export default authService;
