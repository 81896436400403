import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import { Link } from "react-router-dom";

// redux
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { removeUserData, setUserData, selectUserData } from '../stores/userData/userSlice';

import githubIcon from "../assets/images/oauth/github.svg";
import googleIcon from "../assets/images/oauth/google.svg";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  let [error, setError] = useState(false);

  const userData = useAppSelector(selectUserData);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Login | Kerge.lt';
  }, []);

  const handleLogin = async (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    try {
      await AuthService.login(email, password).then(
        (response: any) => {
          console.log(response);
          dispatch(setUserData({ id: response.id, token: response.token, email: response.email, name: response.name }));
          navigate("/");
          window.location.reload();
        },
        (error: any) => {
          console.log(error);
          setError(true)
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="login-page">
      <div className="container">
        <div className="row">
          <div className="login-container">
            <h1>Login</h1>
            
            <form onSubmit={handleLogin}>
            {error ?
              <div className="alert alert-danger" role="alert">There's some error</div>
              : ""}
              <div className="form-group">
                <input
                  type="text"
                  placeholder="email"
                  className="form-control text-input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  placeholder="password"
                  className="form-control text-input"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="form-bottom">
                <Link to={"/user/restore"} className="link__forgot-password">Forgot password?</Link>
                <button className="btn btn_green login__btn" type="submit">Log in</button>
              </div>
              <div className="form-oauth-buttons">
                not available for private build
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Login;
