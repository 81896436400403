import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";
import { Link } from "react-router-dom";

// redux
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { removeUserData, setUserData, selectUserData } from "../../stores/userData/userSlice";

const OauthGithub = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  let [error, setError] = useState(false);

  const userData = useAppSelector(selectUserData);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Github auth | Kerge.lt";
    const code = new URLSearchParams(window.location.search).get("code");
    if (code) {
      AuthService.githubLogin(code).then((response: any) => {
        console.log(response)
        dispatch(setUserData({ id: response.id, token: response.token, email: response.email, name: response.name, oauth_login: response.oauth_login, type: response.type }));
        // navigate("/");
        // window.location.reload();
        window.location.replace("/tasks");
      },
        (error: any) => {
          console.log(error);
          setError(true)
        }
      );
    }
  }, []);

  return <div className="login-page"></div>;
};

export default OauthGithub;
