import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import birdImage from "../assets/images/bird.png"

const Home = (props: any) => {
  const { user } = props;
  const [email, setEmail] = useState("");

  if (user) window.location.replace("/tasks");

  useEffect(() => {
    document.title = 'Kerge.lt | Easy task manager for people with routines';
  }, []);


  return (
    <div>
      go away
    </div>
  )



};

export default Home;
