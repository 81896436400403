import axios from "axios";
import authHeader from "./auth-header"

const API_URL = "https://krgb.velichko.co";

export const fetchRoutines = async () => {
  let result = await axios.get(API_URL + "/routines", { headers: authHeader() })
    .then((response) => response.data)
    .catch((err) => { throw new Error(err) });
  return result
};

const fetchSecond = async () => {
  return 0
};

export const fetchTasks = async (day: string) => {
  const url = API_URL + "/tasks/" + day;
  let result = await axios
    .get(url, { headers: authHeader() })
    .then((response) => response.data)
    .catch((err) => {
      throw new Error(err);
    });
  return result;
};

export const fetchActivitiesInitial = async () => {
  const url = API_URL + "/calendar/default";
  let result = await axios
    .get(url, { headers: authHeader() })
    .then((response) => response.data)
    .catch((err) => {
      throw new Error(err);
    });
  return result;
}

export const fetchFullCalendar = async () => {
  const url = API_URL + "/calendar/full";
  let result = await axios
    .get(url, { headers: authHeader() })
    .then((response) => response.data)
    .catch((err) => {
      throw new Error(err);
    });
  return result;
}

export const fetchUserData = async (id: number) => {
  let result = await axios
    .post(API_URL + "/user/get", {id:id}, { headers: authHeader() })
    .then((response) => {console.log(response); return response.data})
    .catch((err) => {
      throw new Error(err);
    });
  return result;
}

export const fetchRoutine = async (id: any) => {
  let result = await axios
    .get(API_URL + `/routines/${id}`, { headers: authHeader() })
    .then((response) => response.data)
    .catch((err) => {
      throw new Error(err);
    });
  return result;
}

export const setRoutineCategory = async (id: number, categoryId: number) => {
  console.log(id, categoryId)
  let result = await axios
    .post(API_URL + `/routines/category`, {id:id, categoryId:categoryId}, { headers: authHeader() })
    .then((response) => response.data)
    .catch((err) => {
      throw new Error(err);
    });
  return result;
}

export const setRoutineTime = async (id: number, time: number) => {
  console.log("setRoutineTime", id, time)
  let result = await axios
    .post(API_URL + `/routines/time`, {id:id, time:time}, { headers: authHeader() })
    .then((response) => response.data)
    .catch((err) => {
      throw new Error(err);
    });
  return result;
}

// export const getGoogleLink = async () => {
//   let result = await axios.get(API_URL + "/user/google_link")
//   .then((response) => response.data)
//   .catch((err) => { throw new Error(err) });
//   return result
// }