import React, { useState, useEffect, ReactEventHandler } from "react";
import { IRoutine } from "../interfaces/interfaces";
import authHeader from "../services/auth-header";
import axios from "axios";
import RoutineForm from "../components/forms/RoutineForm";
import { fetchRoutines } from "../services/q";
import RoutineItem from "../components/lists/RoutineItem";
import { confirmAlert } from 'react-confirm-alert'; 
const random_emojis = require("../assets/emojis.json");

const API_URL = "https://krgb.velichko.co";

const Routines = (props: any) => {
  let [is_error, setError] = useState(false);
  let [routines, setRoutines] = useState<Array<IRoutine>>([]);
  let [show_form, toggleShowForm] = useState(false);
  let [new_routine, setNewRoutine] = useState({
    name: "",
    icon: random_emojis[Math.floor(Math.random() * random_emojis.length)],
  });
  let [current_routine_form, setCurrentEditRoutine] =
    useState<number | null>(null);

  useEffect(() => {
    document.title = 'Routines | Kerge.lt';

    fetchRoutines()
      .then((routines) => setRoutines(routines))
      .catch(() => {
        setError(true);
      });
  }, []);

  const handleNewRoutineCreation = async (routine: IRoutine) => {
    console.log(routine);
    let result = await axios
      .post(API_URL + "/routines/create", routine, { headers: authHeader() })
      .then((response) => response.data)
      .catch((err) => {
        setError(true);
        throw new Error(err);
      });

    setRoutines([result, ...routines]);
    toggleShowForm(!show_form);
    setNewRoutine({
      name: "",
      icon: random_emojis[Math.floor(Math.random() * random_emojis.length)],
    });
  };

  // EDIT
  const handleRoutineEdition = async (routine: IRoutine) => {
    let id = routine.id;
    const index = routines.findIndex((element) => element.id == routine.id);
    const url = API_URL + "/routines/" + id;
    let result = await axios
      .put(url, routine, { headers: authHeader() })
      .then((response) => response.data)
      .catch((err) => {
        setError(true);
        throw new Error(err);
      });

    let new_routines = routines;
    new_routines[index] = result;
    setRoutines(new_routines);
    setCurrentEditRoutine(null);
  };

  // DELETE
  const handleRoutineDelete = async (routine: IRoutine) => {
    let id = routine.id;
    const index = routines.findIndex((element) => element.id == routine.id);
    console.log("index", index);
    const url = API_URL + "/routines/" + id;
    let result = await axios
      .delete(url, { headers: authHeader() })
      .then((response) => response.data)
      .catch((err) => {
        setError(true);
        throw new Error(err);
      });

    let new_routines = routines;

    new_routines.splice(index, 1);
    setRoutines(new_routines);
    console.log(routines);
    setRoutines(routines.filter((routine) => routine.id != id));
    setCurrentEditRoutine(null);
  };

  const confirmRoutineDelete= (routine: IRoutine) => {
    confirmAlert({
      title: 'Delete a routine',
      message: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleRoutineDelete(routine)
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="page-header">
            <h1>Your routines</h1>

            <a
              href="#"
              className={
                "btn btn_white routines__add-new " + (show_form ? "hidden" : "")
              }
              onClick={() => {
                setCurrentEditRoutine(null);
                toggleShowForm(true);
              }}
            >
              Add new
            </a>
          </div>

          {is_error ? (
            <div className="alert alert-danger tasks__alert" role="alert">
              🚩 We're sorry, something went wrong
            </div>
          ) : (
            ""
          )}

          <div className="block block_nopadding routines__block routines">
            {show_form ? (
              <RoutineForm
                routine={new_routine}
                button_text="Add"
                cancelCallback={() => {
                  toggleShowForm(false);
                }}
                saveCallback={handleNewRoutineCreation}
              />
            ) : (
              ""
            )}

            {routines.length === 0 && !show_form ? <div className="routine_empty">
              🙈 You don't have any routines yet
            </div> : ""}

            {routines.map((routine, index) => (
              routine.active ?
                (<div className="routine" key={routine.id}>
                  {current_routine_form === index ? (
                    <RoutineForm
                      routine={routine}
                      button_text="Save"
                      saveCallback={handleRoutineEdition}
                      cancelCallback={() => setCurrentEditRoutine(null)}
                    />
                  ) : (
                    <RoutineItem
                      routine={routine}
                      index={index}
                      deleteCallback={() => confirmRoutineDelete(routine)}
                      renameCallback={() => {
                        setCurrentEditRoutine(index);
                        toggleShowForm(false);
                      }}
                    />
                  )}
                </div>) : ""
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Routines;
